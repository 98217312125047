import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect:'/login'
  },
  {
    path: '/login',//登录页
    name: 'login',
    component: () => import( '../views/login/Login.vue')
  },
  {
    path: '/rootlogin',//root登录选择机构
    name: 'rootlogin',
    component: () => import( '../views/login/rootLogin.vue')
  },
  {
    path: '/index',//布局页面
    name: 'index',
    component: () => import( '../views/home/Index.vue'),
    children:[
      {
        path: '/Home',//首页
        name: 'Home',
        component: () => import( '../views/home/Home.vue')
      },
      {
        path: '/overview',//总览
        name: 'overview',
        component: () => import( '../views/tatle/overview.vue')
      },
      {
        path: '/reminder',//重要提醒
        name: 'reminder',
        component: () => import( '../views/tatle/reminder.vue')
      },
      {
        path: '/Shift',//照护排班
        name: 'Shift',
        // component: () => import( '../views/tatle/Shift.vue')
        component: () => import( '../views/tatle/csShift.vue')
      },
      {
        path: '/Setup',//设置
        name: 'Setup',
        component: () => import( '../views/tatle/setup.vue')
      },
      {
        path: '/Pos',//定位
        name: 'Pos',
        component: () => import( '../views/tatle/Positioning.vue')
      },
      {
        path: '/Mattress',//床垫
        name: 'Mattress',
        component: () => import( '../views/tatle/Mattress.vue')
      },
      {
        path: '/Millimeter',//毫米波
        name: 'Millimeter',
        component: () => import( '../views/tatle/Millimeter.vue')
      },
      {
        path: '/jiuchun',//毫米波
        name: 'jiuchun',
        component: () => import( '../views/tatle/jiuchun.vue')
      },
      {
        path: '/sxbMattress',//睡小宝床垫
        name: 'sxbMattress',
        component: () => import( '../views/tatle/sxbMattress.vue')
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
//全局路由守卫
router.beforeEach((to, from, next) => {

  var  isLoggedIn = true;
  const userInformation = localStorage.getItem('userInformation');
  const area = localStorage.getItem('area');
  if (!userInformation||!area) {
    isLoggedIn = false;
  }
  if (to.path === '/login'||to.path === '/rootlogin') {
    // 如果去往登录页或管理员登录页，直接放行
    next();
  } else if (isLoggedIn) {
    // 如果有缓存
    next();
  } else {
    // 如果无缓存并且不是去往登录页，重定向到登录页
    next('/login');
  }
});

export default router
