import axios from 'axios';

const request = axios.create({
    // baseURL: 'http://192.168.1.25:8080', // 使用完整的 URL
    baseURL: '/nss', // 使用完整的 URL
    timeout: 30000,
    withCredentials: true, // 允许跨域携带cookie
});

// request 拦截器
request.interceptors.request.use(config => {
    config.headers['Content-Type'] = 'application/json;charset=utf-8';
    let userInfojson = localStorage.getItem('userInformation');
    if(userInfojson) {
        let userInfo = JSON.parse(userInfojson); // 将字符串转换回对象
        config.data.userUuid=userInfo.userUuid;
        config.data.groupId=userInfo.groupId;
        config.data.orgId=userInfo.orgId;
        config.data.staffId=userInfo.staffId;

    }

    return config;
}, error => {
    return Promise.reject(error);
});

// response 拦截器
request.interceptors.response.use(
    response => {
        let res = response.data;
        if (response.config.responseType === 'blob') {
            return res;
        }
        if (typeof res === 'string') {
            res = res ? JSON.parse(res) : res;
        }
        return res;
    },
    error => {
        console.log('err' + error); // for debug
        return Promise.reject(error);
    }
);

export default request;
